@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&family=Outfit:wght@100..900&display=swap');

* {
font-family: 'Fira Code', 'monospace' ;
}

.App {
  background: white;
  margin-bottom: 0px;
  background-image: url('/src/images/Bg.png');
  background-size: contain;
  background-position: center;
}

.app-two {
  /* background-color: #F6F6FF; */
  background-image: url('/src/images/Bg.png');
  background-color: rgba(160, 160, 255, 0.1); /* 50% opacity comes out to #F6F6FF */
  background-size: contain;
  background-position: center;
  margin-top: 0px;
}

body {
  margin: 0;
}